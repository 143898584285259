import React from 'react';

import MakerEnhance from 'maker-enhance';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const Home = ({ location }) => {
	return (
		<Layout location={{ location }} fullWidth="true">
			<SEO titleText="Home" />
			<div style={{minHeight: "calc(100vh - 100px)"}}>
				{ (typeof window !== "undefined" ? <MakerEnhance user="integrations" /> : "") }
			</div>
		</Layout>
	)
}

export default Home;